import React, { useContext, useCallback } from 'react'
import cx from 'classnames'
import { bindActionCreators } from 'redux'

import Avatar from 'piconetworks/pkg-avatar'
import WizardRedux from 'pkgs/WizardRedux'
import { LinkFactory } from 'lib/RouterAdapterDecorator'
import MenuRoutes from 'piconetworks/pkg-menu-router'
import { closeMenu, GO_TO_MENU_ROUTE_AND_OPEN, Menu, CLOSE_MENU } from 'piconetworks/pkg-menu-middleware'
import Picon from 'piconetworks/pkg-picon'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import { colors } from 'piconetworks/pkg-functions'

import style from './style.module.scss'

import Auth from 'modules/auth'
import Account from 'modules/account'
import Loader from 'modules/loader'
import Client from 'modules/client'
import Me from 'modules/me'
import Wizard from 'modules/wizard'
import LandingPage from 'modules/landing-page'
import Plan from 'modules/plan'
import Downloads from 'modules/downloads'
import FeatureFlags from 'modules/feature-flags'
import observers from 'lib/observers'

import { connect, useDispatch } from 'react-redux'
import AppContainerContext from '../AppContainer/AppContainerContext'
import { useRouter } from '../../lib/RouterAdapterDecorator'

import logger from 'lib/logger'

const Link = LinkFactory('AccountMenu')

const AccountMenuWrapper = (props) => {
    const {
        userEmail,
        userEmailIsShared,
        userPhone,
        userAvatarUrl,
        userInitials,
        linkColor,
        contrastColor,
        showMobileOnly,
        currentUser,
        loginAndAccountManagementEnabled,
    } = props

    const router = useRouter()
    const dispatch = useDispatch()

    const { sendEvent, goToCreateAccount } = useContext(AppContainerContext)
    const onClickCreateAnAccount = useCallback(() => {
        router.push(`/${router?.query?.company_slug}/registration`)

        goToCreateAccount()

        dispatch({
            type: CLOSE_MENU
        })
    }, [dispatch, router?.query?.company_slug, router.push])

    return (
        <>
            <Menu
                menuContainerClass={style.menuContainer}
                showMobileOnly={showMobileOnly}
                MenuToggle={() => (
                    currentUser ? (
                        <div className={cx(style.headerButton, { [style.showMobile]: showMobileOnly })}>
                            {userInitials && (
                                <div className={style.avatar}>
                                    <Avatar
                                        email={userEmail}
                                        avatarUrl={userAvatarUrl}
                                        initials={userInitials}
                                        linkColor={linkColor}
                                        contrastColor={contrastColor}
                                        size='100%'
                                    />
                                </div>
                            )}
                            <div>
                                {userEmailIsShared && userEmail ? userEmail : userPhone}
                            </div>
                            <Picon
                                icon='icon_overflow'
                                color='black'
                                size='18'
                                style={{
                                    fontSize: '1rem',
                                    position: 'absolute',
                                    right: '1rem'
                                }}
                            />
                        </div>
                    ) : loginAndAccountManagementEnabled && (
                        <div className={cx(style.headerButton, { [style.showMobile]: showMobileOnly }, style.loginButton)}>
                            <span className={style.button}>
                                Log in
                            </span>
                        </div>
                    )
                )}
                routeProps={{
                    ...props,
                    router,
                    showCreateAccount: false,
                    onClickCreateAnAccount,
                    menuWrappperClass: style.menuWrappper,
                    onPasswordUpdateSuccess: ({ userHasPassword, values = {} }) => {
                        const { password, old_password } = values

                        if (!!password && !userHasPassword) {
                            sendEvent({
                                eventType: 'track',
                                eventName: 'password_created',
                            })
                        }

                        if (!!password && userHasPassword) {
                            sendEvent({
                                eventType: 'track',
                                eventName: 'password_updated',
                            })
                        }

                        props.onPasswordUpdateSuccess()
                    },
                }}
                routes={{
                    '/': (routeProps) => routeProps?.currentUser ? <MenuRoutes.Root {...routeProps} /> : loginAndAccountManagementEnabled && <MenuRoutes.Login.Root {...routeProps} />,
                    ...(loginAndAccountManagementEnabled ? {
                        '/login': (routeProps) => {
                            return (
                                <MenuRoutes.Login.Root {...routeProps} />
                            )
                        },
                        '/login/otp': (routeProps) => {
                            return (
                                <MenuRoutes.Login.Otp {...routeProps} />
                            )
                        },
                        '/login/error': (routeProps) => {
                            return (
                                <MenuRoutes.Login.Error {...routeProps} />
                            )
                        },
                        '/editProfile/editPhone': (routeProps) => (
                            <MenuRoutes.EditProfile.EditPhoneNumber {...routeProps} />
                        ),
                    } : {}),
                    '/editProfile': (routeProps) => (
                        <MenuRoutes.EditProfile.Root {...routeProps} />
                    ),
                    '/editProfile/editName': (routeProps) => (
                        <MenuRoutes.EditProfile.EditName {...routeProps} />
                    ),
                    '/editProfile/editEmail': (routeProps) => (
                        <MenuRoutes.EditProfile.EditEmail {...routeProps} />
                    ),
                    '/editProfile/editPassword': (routeProps) => (
                        <MenuRoutes.EditProfile.EditPassword {...routeProps} />
                    ),
                    '/editProfile/otp': (routeProps) => (
                        <MenuRoutes.EditProfile.Otp {...routeProps} />
                    ),
                    '/editProfile/deleteAccount': (routeProps) => (
                        <MenuRoutes.EditProfile.DeleteAccount {...routeProps} />
                    ),
                    '/manageNewsletters': (routeProps) => (
                        <MenuRoutes.ManageNewsletters.Root {...routeProps} />
                    ),
                    '/manageUpdates': (routeProps) => (
                        <MenuRoutes.ManageUpdates.Root {...routeProps} />
                    ),
                    '/managePlan': (routeProps) => (
                        <MenuRoutes.ManagePlan.Root {...routeProps} />
                    ),
                    // '/managePlan/membership': (routeProps) => (
                    //     <MenuRoutes.ManagePlan.Membership {...routeProps} />
                    // ),
                    '/managePlan/subscription': (routeProps) => (
                        <MenuRoutes.ManagePlan.Subscription.Root
                            {...routeProps}
                        />
                    ),
                    '/managePlan/subscription/stopSubscription': (
                        routeProps,
                    ) => (
                        <MenuRoutes.ManagePlan.Subscription.StopSubscription
                            {...routeProps}
                        />
                    ),
                    '/managePlan/gift': (routeProps) => (
                        <MenuRoutes.ManagePlan.Gift {...routeProps} />
                    ),
                    '/managePlan/subscription/finished': (routeProps) => (
                        <MenuRoutes.ManagePlan.Subscription.Finished
                            {...routeProps}
                        />
                    ),
                    '/managePlan/pass': (routeProps) => (
                        <MenuRoutes.ManagePlan.Pass {...routeProps} />
                    ),
                    '/managePlan/donation': (routeProps) => (
                        <MenuRoutes.ManagePlan.Donation.Root {...routeProps} />
                    ),
                    '/managePlan/donation/change': (routeProps) => (
                        <MenuRoutes.ManagePlan.Donation.Change {...routeProps} />
                    ),
                    '/managePlan/switchPlan': (routeProps) => (
                        <MenuRoutes.ManagePlan.SwitchPlan {...routeProps} />
                    ),
                    '/managePlan/cancelPlan': (routeProps) => (
                        <MenuRoutes.ManagePlan.CancelPlan {...routeProps} />
                    ),
                    '/logOut': (routeProps) => (
                        <MenuRoutes.LogOut.Root {...routeProps} />
                    ),
                    '/notification': (routeProps) => (
                        <MenuRoutes.Notification.Root {...routeProps} />
                    ),
                    '/notification/keepPlan': (routeProps) => (
                        <MenuRoutes.Notification.KeepPlan {...routeProps} />
                    ),
                    '/notification/cancelPlan': (routeProps) => (
                        <MenuRoutes.Notification.CancelPlan {...routeProps} />
                    ),
                }}
            />

        </>
    )
}

const mapStateToProps = (state, props) => {
    const userHasCurrentPurchases = Loader.selectors.userHasCurrentPurchases(state)
    const currentProductOptionId = Loader.selectors.currentProductOptionId(state)
    const shortCode = props?.router?.query?.short_code
    const landingPage = LandingPage.selectors.getLandingPage(state, shortCode)
    const isPayingText = LandingPage.selectors.isPayingText(state, shortCode)
    const company_slug = props?.router?.query?.company_slug
    const isPaidDownloadsEnabled = FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'paid-downloads')
    const origin = typeof window !== 'undefined' ? window?.location?.origin : ''

    let pageContrastColor = {
        value: '#FFFFFF',
    }
    let overriddenContrastColor = null
    if (!isEmpty(landingPage?.form_customizations)) {
        pageContrastColor = landingPage?.form_customizations?.find((p) => {
            return p.property === 'page-contrast-color'
        })
    } else if (!isEmpty(landingPage?.theme_customizations)) {
        pageContrastColor = landingPage?.theme_customizations?.find((p) => {
            return p.property === 'page-contrast-color'
        })
    }
    overriddenContrastColor = colors.getContrastColor(pageContrastColor?.value)

    return {
        Link,
        account: state.account,
        getOutboundClaim: state?.claim?.outboundClaim,
        accountDeleted: state.account.accountDeleted,
        contributionWording: state?.client?.contribution_type,
        loginAndAccountManagementEnabled: FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'login-and-account-management'),
        getEntryRoute: () => '/',
        priceText: Loader.selectors.priceText,
        loginAndAccountManagementEnabled: FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'login-and-account-management'),
        authenticationMethods: state?.client?.authentication_methods,
        defaultAuthenticationMethod: state.client?.default_authentication_method,
        contributionLanguage: Client.selectors.getContributionType(state),
        currentBillingIntervalTotalPaid: Loader.selectors.currentBillingIntervalTotalPaid(state),
        currentProductId: Loader.selectors.currentProductId(state),
        currentProductOptionCurrency: Loader.selectors.currentProductOptionCurrency(state),
        currentPurchaseCanceled: Loader.selectors.currentPurchaseCanceled(state),
        currentPurchaseFailed: Loader.selectors.currentPurchaseFailed(state),
        currentSubscriptionEnd: Loader.selectors.currentSubscriptionEnd(state),
        currentSubscriptionId: Loader.selectors.currentSubscriptionId(state),
        currentSubscriptionIdIsGift: Loader.selectors.currentSubscriptionIdIsGift(state),
        currentSubscriptionInterval: Loader.selectors.currentSubscriptionInterval(state),
        currentSubscriptionStart: Loader.selectors.currentSubscriptionStart(state),
        currentSubscriptionIsTrial: Loader.selectors.currentSubscriptionIsTrial(state),
        currentSubscriptionTypeIsPass: Loader.selectors.currentSubscriptionTypeIsPass(state),
        currentSubscriptionTypeIsRecurringDonation: Loader.selectors.currentSubscriptionTypeIsRecurringDonation(state),
        currentSubscriptionTypeIsSubscription: Loader.selectors.currentSubscriptionTypeIsSubscription(state),
        emailOtpEnabled: FeatureFlags.selectors.emailOtp(state),
        otpRefinementEnabled: true,
        firstDonation: Loader.selectors.firstDonation(state),
        guestLoader: Loader.selectors.loader(state).guest,
        isGroupAdmin: Loader.selectors.isGroupAdmin(state),
        isGroupMember: Loader.selectors.isGroupMember(state),
        userEmailIsShared: Me.selectors.userEmailIsShared(state),
        userPhoneIsShared: Me.selectors.userPhoneIsShared(state),
        userHasMultipleState: Me.selectors.userHasMultipleStates(state),
        userEmail: Me.selectors.userEmail(state),
        userPhone: Me.selectors.userPhone(state),
        isPayingText,
        contrastColor: Client.selectors.contrastColor(state),
        memberNoun: Loader.selectors.memberNoun(landingPage),
        memberType: Loader.selectors.memberType(landingPage),
        memberVerbPresentParticiple: Loader.selectors.memberVerbPresentParticiple(state),
        monetizationIsPledge: Loader.selectors.monetizationIsPledge(landingPage),
        monetizationTypeIsDonation: Loader.selectors.monetizationTypeIsDonation(landingPage),
        monetizationTypeIsGroup: Loader.selectors.monetizationTypeIsGroup(landingPage),
        monetizationTypeIsPass: Loader.selectors.monetizationTypeIsPass(landingPage),
        next: Loader.selectors.next(state),
        nextProductOptionPrice: Loader.selectors.nextProductOptionPrice(state),
        nextStatusNext: Loader.selectors.nextStatusNext(state),
        nextSubscriptionEnd: Loader.selectors.nextSubscriptionEnd(state),
        nextSubscriptionStart: Loader.selectors.nextSubscriptionStart(state),
        nextProductOptionInterval: Loader.selectors.nextProductOptionInterval(state),
        oneTimeCodeError: Account.selectors.oneTimeCodeError(state),
        oneTimeCodeSent: Account.selectors.oneTimeCodeSent(state),
        oneTimeCodeLoading: Account.selectors.oneTimeCodeLoading(state),
        originalEnrollmentDate: Loader.selectors.originalEnrollmentDate(state),
        pricingHasMonetization: Loader.selectors.pricingHasMonetization(landingPage),
        publisherId: Loader.selectors.publisherId(state),
        publisherName: Loader.selectors.publisherName(state),
        pubPriceChangeDate: Loader.selectors.pubPriceChangeDate(state),
        supportEmail: Loader.selectors.supportEmail(state),
        redirectUrl: `${origin}/${company_slug}`,
        topScopeLoader: Loader.selectors.topScopeLoader(state),
        tier: Client.selectors.getAccessTier(state),
        updatePaymentMethodText: Loader.selectors.updatePaymentMethodText(state),
        userAvatarUrl: Loader.selectors.userAvatarUrl(state),
        me: Me.selectors.getCurrentUser(state),
        userEmail: Me.selectors.userEmail(state),
        userFullName: Loader.selectors.userFullName(state),
        userHasCurrentPurchases,
        menuPayload: state._menu.data,
        userHasGroupData: Loader.selectors.userHasGroupData(state),
        groupName: Loader.selectors.groupName(state),
        userHasPassword: Me.selectors.userHasPassword(state),
        userId: Me.selectors.userId(state),
        userInitials: Me.selectors.userInitials(state),
        userLoader: Loader.selectors.loader(state).user,
        userVerified: Me.selectors.userVerified(state),
        menuRoute: state._menu.menuRoute,
        menuPayload: state._menu.data,
        userSyncNewsletters: Me.selectors.userSyncNewsletters(state),
        userNewsletters: Me.selectors.getUserNewslettersData(state),
        // SelectNewsletters,
        // SelectDonation,
        WizardRedux,
        canDeleteAccount: !userHasCurrentPurchases,
        getPlanTexts: (scope) => Loader.selectors.planTexts(state, scope, landingPage),
        featureList: (scope) => Loader.selectors.featureList(state, scope),
        isReadyToMount: !state?.loader?.isFetching && currentProductOptionId,
        isFetching: state.loader?.isFetching,
        getScopedLoader: (scope) => Loader.selectors.scopedLoader(state, scope),
        loader: Loader.selectors.loader(state),
        thisWizard: Wizard.selectors.thisWizard({ state, router: props.router }),
        wizard: state.wizard,
        currentProductOptionId,
        userPhoneIsShared: Me.selectors.userPhoneIsShared(state),
        userSMSOptIn: Me.selectors.userSMSOptIn(state),
        convertGift: () => {
            closeMenu()
            props.router.push({
                pathname: '/[company_slug]/convert-gift',
                query: {
                    ...(shortCode && { short_code: shortCode }),
                    ...(currentProductOptionId && { option_id: currentProductOptionId })
                },
            }, {
                pathname: `/${props?.router?.query?.company_slug}/convert-gift`,
                query: {
                    ...(shortCode && { short_code: shortCode }),
                    ...(currentProductOptionId && { option_id: currentProductOptionId })
                },
            })
        },
        downloads: isPaidDownloadsEnabled
            ? Downloads.selectors.getDownloadableFiles(state)
            : undefined,

        // updateLoaderUserNewsletters: (args) => Internal.creators.sendMessage({
        //     payload: {
        //         target: 'pico_arbiter',
        //         ...Loader.creators.updateLoaderUserNewsletters(args),
        //     },
        // }),
        // queryOverride: {
        //     utmCampaign: state?.zoid?.tag,
        //     utmSource: Loader.selectors.publisherName(state),
        //     utmMedium: process.env.HOSTNAME,
        //     utmContent: 'PopupMenu',
        //     rootUrl: process.env.MARKETING_SITE_URL,
        // },
    }
}


const mapDispatchToProps = (dispatch, otherProps) => ({
    ...bindActionCreators({
        deleteAccount: Account.creators.deleteAccount,
        updateLoaderUser: Loader.creators.updateLoaderUser,
        //  initialize: Account.creators.initialize,
        update: Account.creators.update,
        resetOneTimeCode: Account.creators.resetOneTimeCode
    }, dispatch),
    resendOneTimeCode: async (payload) => {
        try {
            await observers.requestOneTimeCode({
                dispatch,
                payload,
            })
        } catch (error) {
            if (error?.message) {
                throw new Error(error.message)
            } else {
                throw new Error(error)
            }
        }
    },
    newsletterSubscribe: async (payload) => {
        try {
            await observers.updateUserNewsletters({
                dispatch,
                payload: {
                    ...payload,
                    onlyAddNewsletters: false,
                },
            })
        } catch (error) {
            if (error?.message) {
                throw new Error(error.message)
            } else {
                throw new Error(error)
            }
        }
    },
    requestOneTimeCode: async (_payload) => {
        const payload = {
            ..._payload,
        }

        if (!payload.publisherId) {
            payload.publisherId = otherProps?.publisherId || otherProps?.client?.id
        }

        try {
            await observers.requestOneTimeCode({
                dispatch,
                payload,
            })
        } catch (error) {
            if (error?.message) {
                throw new Error(error.message)
            } else {
                throw new Error(error)
            }
        }
    },
    closeMenu: () => { dispatch(closeMenu()) },
    cancelPlan: (payload) => (
        dispatch(Plan.creators.cancelPlan(payload))
    ),
    keepPlan: (payload) => {
        dispatch(Plan.creators.keepPlan(payload))
    },
    onEmailChange: (payload) => {
        dispatch({
            type: GO_TO_MENU_ROUTE_AND_OPEN,
            payload: {
                menuRoute: '/notification',
                text: payload?.text || 'Please check your inbox to verify your new email address.',
                backRoute: '/editProfile',
            },
        })
        dispatch(Me.creators.getCurrentUser({
            payload: {
                publisherId: payload?.publisherId,
            },
        }))
    },
    confirmPhoneOrEmailUpdate: async ({ code, oneTimeCodeLoading, phone, email, origin, publisherId, goToMenuRouteAndOpen, update, updateLoaderUser }) => {

        if (oneTimeCodeLoading) {
            return null
        }

        if (!code) {
            return null
        }

        try {
            const confirmOneTimeCode = await observers.confirmOneTimeCode({
                dispatch,
                payload: {
                    code,
                    email,
                    phone,
                    origin,
                    publisherId,
                    isLogin: true,
                },
            })

            // If we have a claim, get tokens
            if (confirmOneTimeCode.claim !== null) {
                update({
                    updateLoaderUser,
                    publisherId: publisherId || otherProps?.publisherId || otherProps?.client?.id,
                    ...(phone && { phone_number: phone }),
                    ...(email && { email }),

                })
                goToMenuRouteAndOpen('/editProfile')
            }
        } catch (error) {
            if (error?.message) {
                throw new Error(error?.message)
            } else {
                logger.error('confirmPhoneOrEmailUpdate', {}, error)
                throw new Error('an error has occurred')
            }
        }
    },
    onDonationChangeSuccess: () => {
        dispatch({
            type: GO_TO_MENU_ROUTE_AND_OPEN,
            payload: {
                menuRoute: '/notification',
                text: 'Your donation has been updated successfully.',
                backRoute: '/managePlan',
            },
        })
    },
    onNewsletterUpdate: () => {
        dispatch({
            type: GO_TO_MENU_ROUTE_AND_OPEN,
            payload: {
                menuRoute: '/notification',
                text: 'Your newsletter preferences have been successfully updated.',
                backRoute: '/manageNewsletters',
            },
        })
    },
    onPasswordUpdateSuccess: () => {
        dispatch({
            type: GO_TO_MENU_ROUTE_AND_OPEN,
            payload: {
                menuRoute: '/notification',
                text: 'Your password has been successfully updated.',
                backRoute: '/',
            },
        })
    },
    onClickChangePlan: ({ currentProductOptionId, getOutboundClaim }) => {
        console.log(getOutboundClaim)
        const redirectString = qs.stringify({
            ...(getOutboundClaim && { claim: getOutboundClaim }),
        })
        const questionMark = redirectString ? '?' : ''
        const newLocation = `${process.env.CHECKOUT_APP_URL}/${currentProductOptionId}${questionMark}${redirectString}`

        window.location.href = newLocation
    },
    goToMenuRouteAndOpen: (menuRoute, data) => {
        dispatch({
            type: GO_TO_MENU_ROUTE_AND_OPEN,
            payload: {
                menuRoute,
                data,
                ...data,
            },
        })
    },
    // onClickCreateAnAccount: ({ router }) => {
    //     router.push({
    //         pathname: '/[company_slug]/registration',

    //     }, {
    //         pathname: `/${router?.query?.company_slug}/registration`,

    //     })
    //     dispatch({
    //         type: CLOSE_MENU
    //     })
    // },
    onFailureSocialLogin: async ({ goToMenuRouteAndOpen }) => {
        await goToMenuRouteAndOpen('/login/error', { email: 'social account' })
    },
    initializeLoginFlow: async ({ email, phone, publisherId, password, goToMenuRouteAndOpen, hasPassword, setHasPassword }) => {
        try {
            if (!(!email || !phone)) {
                throw Error('email or phone must be provided')
            }

            if (hasPassword) {
                const isAuthed = await observers.login({
                    dispatch,
                    payload: {
                        ...(email && { email }),
                        ...(phone && { phone }),
                        password: password,
                        publisherId: publisherId,
                    },
                })
                if (isAuthed) {
                    dispatch(
                        Me.creators.getCurrentUser({
                            payload: {
                                publisherId,
                            },
                        })
                    )
                    goToMenuRouteAndOpen('/')
                    return {}
                }
            }

            const getHasPassword = await observers.getHasPassword({
                dispatch,
                payload: {
                    email,
                    phone,
                    publisherId,
                },
            })

            if (getHasPassword.isNewRegistration && !getHasPassword.hasPassword) {
                goToMenuRouteAndOpen('/login/error', {
                    email,
                    phone,
                })
                return {}
            }

            if (getHasPassword?.hasPassword) {
                setHasPassword(true)
            } else {
                goToMenuRouteAndOpen('/login/otp', {
                    email,
                    phone,
                })
            }
        } catch (error) {
            goToMenuRouteAndOpen('/login/error', {
                email,
                phone,
            })
            if (error?.message) {
                throw new Error(error.message)
            } else {
                throw new Error(error)
            }
        }
    },
    confirmLoginCode: async ({ code, email, phone, origin, publisherId, goToMenuRouteAndOpen, redirect }) => {
        if (!code) {
            throw new Error('No code provided')
        }

        try {
            const confirmOneTimeCode = await observers.confirmOneTimeCode({
                dispatch,
                payload: {
                    code,
                    email,
                    phone,
                    origin,
                    publisherId,
                    isLogin: true,
                },
            })

            // If we have a claim, get tokens
            if (confirmOneTimeCode.claim !== null) {
                const response = await observers.exchangeClaim({
                    dispatch,
                    payload: {
                        claim: confirmOneTimeCode.claim,
                        scope: 'user',
                        publisherId,
                    },
                })

                dispatch(
                    Auth.creators.tokenLogin({
                        accessToken: response?.tokens?.token,
                        refreshToken: response?.tokens?.refresh_token,
                    })
                )

                dispatch(
                    Me.creators.getCurrentUser({
                        payload: {
                            publisherId,
                        },
                    })
                )

                goToMenuRouteAndOpen(redirect || '/')
            }
        } catch (error) {
            logger.error('confirmLoginCode', {}, error)
            if (error?.message) {
                throw new Error(error?.message)
            }
        }
    },
    onLogin: async ({ publisherId, goToMenuRouteAndOpen, ...tokens }) => {

        if (tokens.is_new_user) {
            goToMenuRouteAndOpen('/login/error')
        }

        if (tokens.claim !== null) {

            const response = await observers.exchangeClaim({
                dispatch,
                payload: {
                    claim: tokens.claim,
                    scope: 'user',
                    publisherId,
                },
            })

            dispatch(
                Auth.creators.tokenLogin({
                    accessToken: response?.tokens?.token,
                    refreshToken: response?.tokens?.refresh_token,
                })
            )

            dispatch(
                Me.creators.getCurrentUser({
                    payload: {
                        publisherId,
                    },
                })
            )

            goToMenuRouteAndOpen('/')
        }
    },
    dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { oneTimeCodeConfirmed, oneTimeCodeLoading, getOutboundClaim } = stateProps
    const { router, publisherId } = ownProps

    const {
        onLogin,
        goToMenuRouteAndOpen,
        onFailureSocialLogin,
        confirmPhoneOrEmailUpdate,
        initializeLoginFlow,
        confirmLoginCode,
        onClickChangePlan,
        // onClickCreateAnAccount,
        update,
        updateLoaderUser
    } = dispatchProps

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        initializeLoginFlow: (payload) => {
            initializeLoginFlow({
                goToMenuRouteAndOpen,
                ...payload,
                publisherId,
            })
        },
        onLogin: (tokens) => {
            onLogin({
                ...tokens,
                goToMenuRouteAndOpen,
                publisherId,
            })
        },
        onFailureSocialLogin: (response) => {
            onFailureSocialLogin({
                ...response,
                goToMenuRouteAndOpen
            })
        },
        // onClickCreateAnAccount: () => {
        //     onClickCreateAnAccount({
        //         router
        //     })
        // },
        onClickChangePlan: (response) => {
            onClickChangePlan({
                currentProductOptionId: response,
                getOutboundClaim
            })
        },
        confirmLoginCode: (payload) => {
            confirmLoginCode({
                ...payload,
                oneTimeCodeConfirmed,
                goToMenuRouteAndOpen,
                publisherId,
            })
        },
        confirmPhoneOrEmailUpdate: (payload) => {
            confirmPhoneOrEmailUpdate({
                ...payload,
                oneTimeCodeConfirmed,
                oneTimeCodeLoading,
                update,
                updateLoaderUser,
                goToMenuRouteAndOpen,
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountMenuWrapper)
