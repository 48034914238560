import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'

import MenuWrapper from './components/MenuWrapper'
import Avatar from 'piconetworks/pkg-avatar'
import Boutton from 'piconetworks/pkg-boutton'
import { colors } from 'piconetworks/pkg-functions'
import Tooltip from 'piconetworks/pkg-tooltip'
import Picon from 'piconetworks/pkg-picon'

// use a dynamic import instead (this is ~275kb in build)
import Icon, { Icons } from 'piconetworks/pkg-icon'

import style from './scss/MenuRoutes.module.scss'

const WarnPaymentMethod = ({ label = 'Please update your payment method.' }) => (
    <span >
        <Tooltip
            label={label}
            placement="top"
            className=""
        >
            <Icon
                icon={Icons.Warning}
                height={18}
                width={18}
            />
        </Tooltip>
    </span>
)


const Root = ({
    Link,
    changeMenuRoute,
    currentPurchaseFailed,
    currentSubscriptionId,
    currentSubscriptionTypeIsPass,
    currentSubscriptionTypeIsRecurringDonation,
    currentSubscriptionTypeIsSubscription,
    isGroupAdmin,
    isGroupMember,
    linkColor,
    monetizationIsPledge,
    monetizationTypeIsDonation,
    monetizationTypeIsGroup,
    monetizationTypeIsPass,
    publisherId,
    pricingHasMonetization,
    publisherName,
    supportEmail,
    userAvatarUrl,
    userEmail,
    userEmailIsShared,
    userHasCurrentPurchases,
    userHasGroupData,
    userInitials,
    userPhone,
    userVerified,
    closeMenu,
    sendExternalLink,
    tier,
    oneTimeCodeError,
    goToMenuRouteAndOpen,
    requestOneTimeCode,
    utmRootUrl,
    queryOverride,
    downloads = [],
    loginAndAccountManagementEnabled,
    contributionLanguage = 'tip',
}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const contrastColor = colors.getContrastColor(linkColor)
    const isTier1 = tier === 'tier_1'
    const isGmail = userEmail && userEmail.length > 10 && userEmail.slice(-10) === '@gmail.com'
    const helpMailto = (!isIOS && isGmail) ?
        `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${supportEmail}` :
        `mailto:${supportEmail}`
    const currentSubscriptionIdIsGift = currentSubscriptionId === 'GIFT'

    useEffect(() => {
        oneTimeCodeError && setErrorMessage(oneTimeCodeError)
    }, [oneTimeCodeError])

    return (
        <MenuWrapper publisherName={publisherName} sendExternalLink={sendExternalLink} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={classNames(style.mainMenuContainer)}>
                <div className={style.accountWrapper}>
                    <div>
                        <div className={style.accountHeader}>My Account</div>
                        <div className={style.email}>{userEmailIsShared && userEmail ? userEmail : userPhone}</div>

                    </div>
                    <div className={style.menuAvatar}>
                        <Avatar
                            contrastColor={contrastColor}
                            email={userEmail}
                            initials={userInitials}
                            linkColor={linkColor}
                            size="96px"
                            src={userAvatarUrl}
                        />
                    </div>
                </div>
                <nav className={style.mainNavigation} style={{ color: linkColor }}>
                    <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/editProfile') }}>
                        <Boutton
                            variant="link"
                            icon={<Picon icon='icon_profile' size='24' color={linkColor} />}
                        >
                            Edit profile
                        </Boutton>
                    </li>
                    {!userVerified && (
                        <li className={style.verificationWarning}>
                            <Boutton
                                onClick={() => {
                                    goToMenuRouteAndOpen('/editProfile/otp', {
                                        email: userEmail,
                                        verify: 'email',
                                        requestOneTimeCode
                                    })
                                }}
                                icon={<Picon icon='icon_warning' size='24' color='#FCAF2F' />}
                                variant="link"
                            >
                                Verify your email address
                            </Boutton>
                            {errorMessage && (
                                <div className={style.error}>{errorMessage}</div>
                            )}
                        </li>
                    )}
                    {!loginAndAccountManagementEnabled ? (
                        <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/manageNewsletters') }}>
                            <Boutton
                                icon={<Picon icon='icon_newsletter' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Manage newsletters
                            </Boutton>
                        </li>
                    ) : (
                        <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/manageUpdates') }}>
                            <Boutton
                                icon={<Picon icon='icon_newsletter' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Manage updates
                            </Boutton>
                        </li>
                    )}
                    {/* {currentSubscriptionTypeIsSubscription
                            && !currentSubscriptionIdIsGift
                            && (
                                <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/membership') }}>
                                    <Boutton
                                        icon={<Picon icon='icon_reload' size='24' color={linkColor} />}
                                        variant="link"
                                    >
                                        Manage membership {currentPurchaseFailed && <WarnPaymentMethod />}
                                    </Boutton>
                                </li>
                            )} */}
                    {!userHasCurrentPurchases
                        && pricingHasMonetization
                        && monetizationIsPledge
                        && (
                            pricingHasMonetization
                            && !monetizationTypeIsGroup
                            && (
                                !userHasGroupData || (
                                    userHasGroupData && (
                                        !isGroupAdmin
                                        && !isGroupMember
                                    )
                                )
                            )
                        )
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/checkout"
                                    as={'/checkout'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='pay' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            Become a member
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {(
                        currentSubscriptionTypeIsSubscription
                        && currentSubscriptionIdIsGift
                    ) && (
                        <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/gift') }}>
                            <Boutton
                                icon={<Picon icon='icon_reload' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Manage subscription
                            </Boutton>
                        </li>
                    )}
                    {!currentSubscriptionIdIsGift && ((isGroupMember || (currentSubscriptionTypeIsSubscription)))
                        && (
                            <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/subscription') }}>
                                <Boutton
                                    icon={<Picon icon='icon_reload' size='24' color={linkColor} />}
                                    variant="link"
                                >
                                    Manage subscription {currentPurchaseFailed && <WarnPaymentMethod />}
                                </Boutton>
                            </li>
                        )}
                    {!userHasCurrentPurchases
                        && (
                            pricingHasMonetization
                            && !monetizationTypeIsPass
                        )
                        && (
                            pricingHasMonetization
                            && !monetizationTypeIsDonation
                        )
                        && (
                            pricingHasMonetization
                            && !monetizationIsPledge
                        )
                        && (
                            pricingHasMonetization
                            && !monetizationTypeIsGroup
                            && (
                                !userHasGroupData || (
                                    userHasGroupData && (
                                        !isGroupAdmin
                                        && !isGroupMember
                                    )
                                )
                            )
                        )
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/checkout"
                                    as={'/checkout'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='pay' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            Subscribe
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {(
                        isGroupAdmin
                            && !isGroupMember
                    )
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/group"
                                    as={'/group'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='pay' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            Join group subscription
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {currentSubscriptionTypeIsRecurringDonation
                        && (
                            <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/donation') }}>
                                <Boutton
                                    icon={<Picon icon='icon_reload' size='24' color={linkColor} />}
                                    variant="link"
                                >
                                    Manage {contributionLanguage === 'tip' ? 'tip' : 'donation'}
                                </Boutton>
                            </li>
                        )}
                    {currentSubscriptionTypeIsPass
                        && (
                            <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/pass') }}>
                                <Boutton
                                    icon={<Picon icon='pay' size='24' color={linkColor} />}
                                    variant="link"
                                >
                                    View pass
                                </Boutton>
                            </li>
                        )}
                    {!userHasCurrentPurchases
                        && pricingHasMonetization
                        && monetizationTypeIsPass
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/checkout"
                                    as={'/checkout'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='pay' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            Buy a pass
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {!userHasCurrentPurchases
                        && pricingHasMonetization
                        && monetizationTypeIsDonation
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/checkout"
                                    as={'/checkout'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='pay' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            {contributionLanguage === 'tip' ? 'Tip' : 'Donation'}
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {pricingHasMonetization
                        && !monetizationTypeIsDonation
                        && (
                            <li className={style.mainMenuItem}>
                                <Link
                                    href="/gift"
                                    as={'/gift'}
                                >
                                    <a>
                                        <Boutton
                                            icon={<Picon icon='icon_gift' size='24' color={linkColor} />}
                                            onClick={() => {
                                                closeMenu()
                                            }}
                                            variant="link"
                                        >
                                            Gift a subscription
                                        </Boutton>
                                    </a>
                                </Link>
                            </li>
                        )}
                    {!!downloads.length && (
                        <li className={style.mainMenuItem}>
                            <Link
                                href="/downloads"
                                as={'/downloads'}
                            >
                                <a
                                    className={classNames(style.button, style.danger)}
                                    onClick={() => {
                                        closeMenu()
                                    }}
                                >
                                    <Boutton
                                        icon={<Picon icon='download' size='24' color={linkColor} />}
                                        onClick={() => {
                                            closeMenu()
                                        }}
                                        variant="link"
                                    >
                                        View downloads
                                    </Boutton>
                                </a>
                            </Link>
                        </li>
                    )}
                    {supportEmail && (
                        <li className={style.mainMenuItem}>
                            <Boutton
                                icon={<Picon icon='icon_help' size='12' color={linkColor} />}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (sendExternalLink) {
                                        sendExternalLink(helpMailto)
                                    } else {
                                        window.open(helpMailto)
                                    }
                                }}
                                variant="link"
                            >
                                Help
                            </Boutton>
                        </li>
                    )}
                    <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/logOut') }}>
                        <Boutton
                            icon={<Picon icon='icon_exit' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Log out
                        </Boutton>
                    </li>
                </nav>
            </div>
        </MenuWrapper >
    )
}

Root.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null
}

Root.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    closeMenu: PropTypes.func,
    contributionLanguage: PropTypes.string,
    currentPurchaseFailed: PropTypes.any,
    currentSubscriptionId: PropTypes.any,
    currentSubscriptionIdIsGift: PropTypes.bool,
    currentSubscriptionTypeIsPass: PropTypes.bool,
    currentSubscriptionTypeIsRecurringDonation: PropTypes.bool,
    currentSubscriptionTypeIsSubscription: PropTypes.bool,
    goToMenuRouteAndOpen: PropTypes.func,
    isGroupAdmin: PropTypes.bool,
    isGroupMember: PropTypes.bool,
    Link: PropTypes.any,
    linkColor: PropTypes.string,
    monetizationIsPledge: PropTypes.bool,
    monetizationTypeIsDonation: PropTypes.bool,
    monetizationTypeIsGroup: PropTypes.bool,
    monetizationTypeIsPass: PropTypes.bool,
    pricingHasMonetization: PropTypes.bool,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    sendExternalLink: PropTypes.func,
    supportEmail: PropTypes.string,
    userAvatarUrl: PropTypes.string,
    userEmail: PropTypes.string,
    userHasCurrentPurchases: PropTypes.bool,
    userHasGroupData: PropTypes.bool,
    userInitials: PropTypes.string,
    userVerified: PropTypes.bool,
    utmRootUrl: PropTypes.string,
}

export default Root
